import jwt_decode from 'jwt-decode';
import userService from '../api';
import productsService from '../../products/api';
import {
  SET_CART,
  SET_AUTH,
  DEL_CART,
  SET_USER,
  SET_SEARCH,
  SET_USER_AVATAR,
  SET_CART_PRODUCT,
  DEL_CART_PRODUCT,
  SET_USER_LOADING,
  SET_SERVER_ERROR,
  SET_CURRENT_USER,
  SET_CART_PRODUCTS,
  SET_TIP_AMOUNT,
  SET_RESULT_SEARCH,
  SET_PAYMENT_STATUS,
  SET_NETWORK_STATUS,
  SET_PURCHASES_LIMIT,
  SET_PRODUCT_INVOICE,
  SET_SHOWROMS_PRODUCT,
  SET_CART_LIMIT_POPUP,
  SET_CART_LIMIT,
  SET_USER_BILLS_DATA,
  SELECT_ALL_ITEMS_CART,
  SELECT_ONE_ITEMS_CART,
  DEL_SHOWROOMS_PRODUCT,
  SET_PUBLIC_STRIPE_TOKEN,
  SET_CART_SHOWROOMS_PRODUCTS,
  SET_LOCALE,
  SET_ACTIVATED_EMAIL_MODAL,
  SET_LOGIN_MODAL_SHOW,
  SET_SIGNUP_MODAL_SHOW,
  SET_RECOVERY_MODAL_SHOW,
  SET_GET_FREE_MODAL_SHOW,
  SET_PAYMENT_DATA,
  SET_BILLS_AMOUNT,
  SET_PAID_PROJECTS,
  SET_PAID_PRODUCTS,
  SET_BILLS_LOADING,
  SET_PURCHASES_LOADING,
  SET_SEARCH_PAGE,
  SET_DEVICE_TYPE,
  SET_MOBILE_SCREEN,
  SET_VISIBLE_SUBFOOTER,
  SET_CART_LOADING,
} from './user.constants';
import { BASE_URL, ADMIN_ROUTES } from '@app/routes';
import { replaceTo } from '@lib/utils';
import {
  User,
  Snackbar,
  setAlertAC,
  SimilarProductDTO,
  projectInstance,
  setSelectedUserAC,
  setCartToActiveProductAC,
  setCartToSimilarProductsAC,
  setPaginationAmountAC,
  setProjectsPaginationAmountAC,
} from '@entities';

import { dateLib } from '@shared';

/*
 * get csrf token to cookeies
 * get tokens
 **/
export const checkAuthTC = () => {
  return async (dispatch) => {
    try {
      const resp = await userService.getTokenCsrf();

      if (!resp?.data) {
        return;
      }

      const data = resp.data;

      localStorage.setItem('_csrf', data.token);

      const response = await userService.checkAuth();
      const { accessToken } = response?.data || {};

      if (!accessToken) {
        return;
      }

      const user = jwt_decode(accessToken);
      dispatch(setUserAC(user));
      localStorage.setItem('token', accessToken);
    } catch (error) {
      dispatch(setAuthAC(false));
      if (error.response) {
        const status = error.response.status;
        const errors = error?.response?.data?.errors || {};
        if (status === 400) {
          return dispatch(setServerErrorsAC(errors));
        }

        if (status !== 403) {
          console.log('[ERROR]', errors)
        }
      }
    } finally {
      dispatch(getShowromsProductsTC());
    }
  };
};

export const loginTC = (formData, hash) => {
  return async (dispatch) => {
    try {

      const localTime = dateLib.getDateQueryString(new Date());

      formData.local_time = localTime;
      const { data } = await userService.login(formData);
      const { user, accessToken } = data;

      const usersDTO = new User(user);
      dispatch(setUserAC(usersDTO));
      localStorage.setItem('token', accessToken);

      return usersDTO;
    } catch (error) {
      if (error.response) {
        const status = error.response.status;
        if (status === 400) {
          const errors = error?.response?.data?.errors || {};
          dispatch(setServerErrorsAC(errors));
        }
      }
    }
  };
};

export const logoutTC = () => {
  return async (dispatch) => {
    await userService.logout();

    dispatch(setUserAC({}));
    dispatch(setAuthAC(false));
    dispatch(setCartAC([]));

    window.socket.close();
    localStorage.removeItem('token');

    const location = window.location;
    const isAdminLocation = ADMIN_ROUTES.filter((item) =>  location.pathname.includes(item.path.pathname));

    if (isAdminLocation.length) {
      return location.href = BASE_URL.pathname;
    }
    location.reload();
  };
};

export const recoveryPasswordTC = (formData) => {
  return async (dispatch) => {
    try {
      await userService.recoveryPassword(formData);
      return true;
    } catch (error) {
      if (error.response) {
        const status = error.response.status;
        if (status === 400) {
          const errors = error?.response?.data?.errors || {};
          dispatch(setServerErrorsAC(errors));
        }
      }
    }
  };
};

export const resetPasswordTC = (formData) => {
  return async (dispatch) => {
    try {
      await userService.resetPassword(formData);

      return true;
    } catch (error) {
      if (error.response) {
        const status = error.response.status;
        if (status === 400) {
          const errors = error?.response?.data?.errors || {};
          dispatch(setServerErrorsAC(errors));
        }
      }
    }
  };
};

export const updatePasswordTC = (formData, user) => {
  return async (dispatch) => {
    try {
      await userService.updatePassword(formData);

      const snackbar = new Snackbar({
        status: 200,
        message: (
          <div className="">
            <h5 className="snack__header">
              Password updated.
            </h5>
          </div>
        ),
      });
      dispatch(setAlertAC(snackbar));

      return true;
    } catch (error) {
      if (error.response) {
        const status = error.response.status;
        if (status === 400) {
          const errors = error?.response?.data?.errors || {};
          dispatch(setServerErrorsAC(errors));
        }
      }
      const snackbar = new Snackbar({
        status: 500,
        message: (
          <div className="">
            <h5 className="snack__header">
              Password has not been updated! Try update your password again or contact technical support.
            </h5>
          </div>
        ),
      });
      dispatch(setAlertAC(snackbar));
      console.log('[ERROR]', error);
    }
  };
};

export const sendActivationOnEmailTC = (userEmail) => {
  return async (dispatch) => {
    try {
      await userService.confirmEmail(userEmail);

      const snackbar = new Snackbar({
        status: 200,
        message: (
          <div className="">
            <h5 className="snack__header">
              A link to verify your account has been sent to the email address you provided.
            </h5>
          </div>
        ),
      });
      dispatch(setAlertAC(snackbar));

      return true;
    } catch (error) {
      if (error.response) {
        const status = error.response.status;
        if (status === 400) {
          const errors = error?.response?.data?.errors || {};
          dispatch(setServerErrorsAC(errors));
        };
      };
    }
  };
} 

export const createUserTC = (user) => {
  return async (dispatch) => {
    try {
      return await userService.createUser(user);
    } catch (error) {
      if (error.response) {
        const status = error.response.status;
        if (status === 400 || status === 422) {
          const errors = error?.response?.data?.errors || {};
          dispatch(setServerErrorsAC(errors));
        }
      }
    }
  };
};

export const getUserTC = (id) => {
  return async (dispatch) => {
    try {
      const response = await userService.getUser(id);

      const user = response.data;
      const usersDTO = new User(user);

      dispatch(setCurrentUserAC(usersDTO));

      dispatch(setSelectedUserAC(usersDTO.id));
    } catch (error) {
      console.log(`[ERROR] ${error}`);
    }
  };
};

export const updateUserTC = (id, user) => {
  return async (dispatch) => {
    try {
      const data = await userService.updateUser(id, user);

      if(data.status === 200) {
        const snackbar = new Snackbar({
          status: 200,
          message: (
            <div className="">
              <h5 className="snack__header">
                User updated successfully.
              </h5>
            </div>
          ),
        });

        dispatch(setAlertAC(snackbar));
      }
    } catch (error) {
      console.log(`[ERROR] ${error}`);
    }
  };
};

export const updateUserSettingsTC = (user) => {
  return async (dispatch) => {
    try {
      await userService.updateUserSettings(user);

      dispatch(setUserAC(user));

      const snackbar = new Snackbar({
        status: 200,
        message: (
          <div className="">
            <h5 className="snack__header">
              Profile updated successfully.
            </h5>
          </div>
        ),
      });

      dispatch(setAlertAC(snackbar));
    } catch (error) {
      if (error.response) {
        const status = error.response.status;
        if (status === 400) {
          const errors = error?.response?.data?.errors || {};
          dispatch(setServerErrorsAC(errors));
        };
      };
    }
  };
};

export const updateUserSettingsCountryTC = (user) => {
  return async (dispatch) => {

    await userService.updateUserSettingsCountry(user);

    dispatch(setUserAC(user));
  };
};

export const updateUserAvaTC = (avatar, user) => {
  return async (dispatch) => {
    return await userService.updateUserAva(avatar, user);
  };
};

export const deleteUserTC = (id) => {
  return async (dispatch) => {
    return await userService.deleteUser(id);
  };
};

export const getCartTC = (basketId) => {
  return async (dispatch) => {
    try {
      dispatch(setCartLoadingAC(true));
      const response = await userService.getCart(basketId);

      if (response?.status === 200) {
        const { basket } = response.data;

        if (!basket?.products) {
          return;
        }

        const corrected_rows = basket.products.map((item) => {
          const clearFormats = replaceTo(
            item.illustration_formats,
            '&quot;',
            '"'
          );
          item.illustration_formats = JSON.parse(clearFormats);

          return item;
        });

        const MAX_CART_CAPACITY = 50;
        if (corrected_rows.length >= MAX_CART_CAPACITY) {
          dispatch(setCartLimitAC(true));
        }
        
        dispatch(setCartAC(corrected_rows));
        dispatch(setProductsInCartAC({ flag: true, cart: corrected_rows }));
        dispatch(setProductInCartAC({ flag: true, cart: corrected_rows }));
        dispatch(
          setShowroomsProductsInCartAC({ flag: true, cart: corrected_rows })
        );
      };
    } catch (error) {
      console.log(`[ERROR] ${error}`);
    } finally {
      dispatch(setCartLoadingAC(false));
    }
  };
};

export const addProductToCartTC = (product, callback) => {
  return async (dispatch, getState) => {
    const userData = getState()?.userData;

    if (!userData.isAuth && !userData.user.id) {
      return dispatch(setModalLoginShowAC(true));
    }

    const { user, cart = [] } = userData;

    const MAX_CART_CAPACITY = 50;

    if (cart.length >= MAX_CART_CAPACITY) {
      dispatch(setCartLimitAC(true));
      return dispatch(setCartLimitpopupAC(true));

    }

    await userService.addProductToCart(user.basketId, product.id);

    dispatch(setProductsInCartAC({ flag: true, cart: [product] }));
    const isProductAlreadyInCart = cart.find((item) => item.id === product.id);

    if (!isProductAlreadyInCart) {
      dispatch(setCartAC([...cart, product]));
      dispatch(setCartToActiveProductAC({ flag: true, productId: product.id }));
      dispatch(setCartToSimilarProductsAC({ flag: true, cart: [product] }));
      dispatch(setShowroomsProductsInCartAC({ flag: true, cart: [product] }));
    }

    const snackbar = new Snackbar({
      status: 200,
      message: (
        <div className="">
          <h5 className="snack__header">Illustration was added to the cart</h5>
        </div>
      ),
    });

    dispatch(setAlertAC(snackbar));

    if (callback) {
      callback();
    }
  };
};

export const deleteProductFromCartTC = (cartId, productId) => {
  return async (dispatch) => {
    await userService.deleteProductFromCart(cartId, productId);

    dispatch(deleteProductInCartAC(productId));
    dispatch(deleteProductFromShowroomAC(productId));
    dispatch(setCartLimitAC(false));

    const snackbar = new Snackbar({
      status: 200,
      message: (
        <div className="">
          <h5 className="snack__header">
            Illustration was removed from the cart
          </h5>
        </div>
      ),
    });

    dispatch(setAlertAC(snackbar));
  };
};

export const resetCartTC = (cartId) => {
  return async (dispatch) => {
    await userService.resetCart(cartId);

    dispatch(resetCartAC(cartId));
  };
};

export const getStripeSettingsTC = () => {
  return async (dispatch) => {
    const data = await userService.getPublickStripeSettings();

    if (!data) {
      return;
    }

    const publicToken = data?.data?.publicToken || null;
    dispatch(setStripePublicTokenAC(publicToken));
  };
};

export const getInvoiceTC = (body) => {
  return async (dispatch) => {
    dispatch(setUserPreloaderAC(true));
    try {
      const response = await userService.getInvoice(body);
      dispatch(setProductInvoiceAC(response.data));
    } catch (error) {
      console.log(`[ERROR] ${error}`);
      const snackbar = new Snackbar({
        status: 500,
        message: (
          <div className="">
            <h5 className="snack__header">
              Failed to retrieve invoice. Please refresh the page and try again.
            </h5>
          </div>
        ),
      });
  
      dispatch(setAlertAC(snackbar));
      dispatch(setServerErrorsAC([{param: 500, msg: error}]));
    } finally {
      dispatch(setUserPreloaderAC(false));
    }
  };
};

export const payInvoiceTC = (userForm) => {
  return async (dispatch, getState) => {
    dispatch(setUserPreloaderAC(true));
    try {
      const userData = getState().userData;

      const { invoice, user, paymentData } = userData;
      const paymentMethod = await createPaymentIntentTC(user.email);

      const settings = {
        country: userForm.country,
        line1: userForm.address,
        postal_code: userForm.postCode,
        customerId: invoice.customer,
      };

      if (userForm.hasOwnProperty('company')) {
        settings['companyName'] = userForm.company;
      }

      if (userForm.hasOwnProperty('tax')) {
        settings['companyVat'] = userForm.tax;
      }

      if (userForm.hasOwnProperty('taxType')) {
        settings['taxType'] = userForm.taxType;
      }

      const options = {
        settings,
        type: paymentData.type,
        projectId: paymentData?.projectId,
        notificationId: paymentData?.notificationId,
        basketId: user.basketId,
        paymentMethodId: paymentMethod.id,
      };

      if (paymentData.type === 'product') {
        delete options['projectId'];
        delete options['notificationId'];
      }

      const response = await userService.payInvoice(invoice.invoiceId, options);

      if (response?.status === 200) {
        dispatch(setPaymentsStatusAC('success'));
      } else {
        dispatch(setPaymentsStatusAC('false_server'));
      }
    } catch (error) {
      let errorMess = error?.response?.data?.message || error;

      if (error.response) {
        const isStripe = error.response?.data?.errors[0]?.isStripeError;
        const status = error.response.status;

        if (isStripe) {
          errorMess = 'Incorrect card information or insufficient funds.';
          dispatch(setPaymentsStatusAC('false_user'));
        }

        if (status === 422) {
          errorMess = 'Invalid personal tax number format.';
        } 
        
        if (status !== 422 && status !== 402) {
          dispatch(setPaymentsStatusAC('false_server'));
        }

        dispatch(setPaymentsStatusAC('false_user'));

      } else {
        dispatch(setPaymentsStatusAC('false_user'));
      }

      const snackbar = new Snackbar({
        status: 500,
        message: (
          <div className="">
            <h5 className="snack__header">
              Payment failed. {errorMess}
            </h5>
          </div>
        ),
      });
      dispatch(setAlertAC(snackbar));
      dispatch(setServerErrorsAC([{ msg: errorMess }]));

      console.log('[Error]', error);
    } finally {
      dispatch(setUserPreloaderAC(false));
    }
  };
};

export const createPaymentIntentTC = (email) => {
  return new Promise(async (resolve, reject) => {
    if (!window.PAYMENT_ELEMENT || !window.STRIPE) {
      return;
    }

    try {
      const option = {
        type: 'card',
        card: window.PAYMENT_ELEMENT,
        billing_details: {
          email,
        },
      };

      const result = await window.STRIPE.createPaymentMethod(option);

      if(result?.error) {
        reject(result?.error?.message);
      }

      resolve(result.paymentMethod);
    } catch (e) {
      reject(e);
    }
  });
};

export const getUserBillsTC = (params) => {
  return async (dispatch) => {
    try {
      dispatch(setBillsLoadingAC(true));
      const data = await userService.getBills(params);

      let { rows, count } = data.data;

      if (!count) {
        count = 1;
      };

      dispatch(setUserBillsAC(rows));
      dispatch(setBillsPaginationAmountAC(count));
    } catch (error) {
      console.log('[ERROR]', error);
    } finally {
      dispatch(setBillsLoadingAC(false));
    };
  };
} ;

export const sendJobFormTC = (formData) => {
  return async (dispatch) => {
    try {
      formData['full_name'] = `${formData.firstName} ${formData.lastName}`;
      delete formData['firstName'];
      delete formData['lastName'];

      const response = await userService.sendJobForm(formData);

      if (response.status === 200) {
        const snackbar = new Snackbar({
          status: 200,
          message: (
            <div className="">
              <h5 className="snack__header">
                Форма успешно отправлена
              </h5>
            </div>
          ),
        });
        dispatch(setAlertAC(snackbar));
        return true;
      };

      throw response;
    } catch (error) {
      const snackbar = new Snackbar({
        status: 500,
        message: (
          <div className="">
            <h5 className="snack__header">
              Ошибка отправки формы. Попробуй ещё раз или свяжись с поддержкой.
            </h5>
          </div>
        ),
      });
      dispatch(setAlertAC(snackbar));
      console.log(`[ERROR] ${error}`);
      return false;
    }
  };
}

//SHOWROMS_PRODUCT
export const getShowromsProductsTC = () => {
  return async (dispatch) => {
    const orderBy = 'popular';
    const limit = 10;
    const page = 1;
    const scale = 1920;

    let request = `?orderBy=${orderBy}&limit=${limit}&page=${page}&scale=${scale}`;

    const response = await productsService.getRecommendedProducts(request);

    if (response?.status === 200) {
      const { rows } = response.data;
      const showroomsProducts = rows.map((item) => new SimilarProductDTO(item));

      return dispatch(setShowRoomsProductsAC(showroomsProducts));
    }

    return dispatch(setShowRoomsProductsAC([]));
  };
};

export const getPaidProductsTC = (options) => {
  return async (dispatch) => {
    try {
      dispatch(setPurchasesLoadingAC(true));
      const data = await userService.getPaidProducts(options);

      let { rows, count } = data.data;

      if (!count) {
        count = 1;
      }

      dispatch(setPaidProductsAC(rows));
      dispatch(setPaginationAmountAC(count));
    } catch (error) {
      console.log('[ERROR]', error);
    } finally {
      dispatch(setPurchasesLoadingAC(false));
    }
  };
};

export const getPaidProjectsTC = (options) => {
  return async (dispatch) => {
    try {
      const data = await userService.getPaidProjects(options);

      let { rows, count } = data.data;

      if (!count) {
        count = 1;
      }

      dispatch(setPaidProjectsAC(rows.map(proj => projectInstance.create(proj, 'userProject'))));
      dispatch(setProjectsPaginationAmountAC(count));
    } catch (error) {
      console.log('[ERROR]', error);
    }
  };
};

export const sendSubscribeFormTC = (formData) => {
  return async (dispatch) => {
    try {
      await userService.sendSubscribeForm(formData);

      const snackbar = new Snackbar({
        status: 250,
        duration: 5000,
        message: (
          <div className="">
            <h5 className="snack__header">
              You have successfully subscribed to our newsletter
            </h5>
            <div className="snack__text">
              Thank you for subscribing! Now you can receive amazing 
              sets and inspiration from the BriefStock team every week.
            </div>
          </div>
        ),
      });

      dispatch(setAlertAC(snackbar));

    } catch (error) {
      if (error.response) {
        const status = error.response.status;
        if (status === 400) {
          const errors = error?.response?.data?.errors || {};
          return dispatch(setServerErrorsAC(errors));
        };
      } 

      const snackbar = new Snackbar({
        status: 500,
        message: (
          <div className="">
            <h5 className="snack__header">
              Subscription error.
            </h5>
          </div>
        ),
      });
      dispatch(setAlertAC(snackbar));
      console.log(`[ERROR] ${error}`);
    }
  };
};

export const setModalGetFreeProductTC = (data) => {
  return async (dispatch, getState) => {
    const userData = getState()?.userData;

    if (!userData.isAuth && !userData.user.id) {
      return dispatch(setModalLoginShowAC(true));
    }

    dispatch(setModalGetFreeProductAC(data));
  }
};

export const setAuthAC = (isAuth) => {
  return {
    type: SET_AUTH,
    payload: isAuth,
  };
};

export const setUserAC = (user) => {
  return {
    type: SET_USER,
    payload: new User( user ),
  };
};

export const setNetworkAC = (user) => {
  return {
    type: SET_NETWORK_STATUS,
    payload: user,
  };
};

export const setServerErrorsAC = (errors) => {
  return {
    type: SET_SERVER_ERROR,
    payload: errors,
  };
};

export const setSearchAC = (text) => {
  return {
    type: SET_SEARCH,
    payload: text,
  };
};

export const setResultSearchAC = (text) => {
  return {
    type: SET_RESULT_SEARCH,
    payload: text,
  };
};

export const setCartAC = (cartArray) => {
  return {
    type: SET_CART,
    payload: cartArray,
  };
};

export const selectAllItemsCartAC = (flag) => {
  return {
    type: SELECT_ALL_ITEMS_CART,
    payload: flag,
  };
};

export const selectOneItemsCartAC = (payload) => {
  return {
    type: SELECT_ONE_ITEMS_CART,
    payload,
  };
};

export const setProductsInCartAC = (payload) => {
  return {
    type: SET_CART_PRODUCTS,
    payload,
  };
};

export const setShowroomsProductsInCartAC = (payload) => {
  return {
    type: SET_CART_SHOWROOMS_PRODUCTS,
    payload,
  };
};

export const setProductInCartAC = (payload) => {
  return {
    type: SET_CART_PRODUCT,
    payload,
  };
};

export const setTipAmountAC = (payload) => {
  return {
    type: SET_TIP_AMOUNT,
    payload,
  };
};

export const setShowRoomsProductsAC = (payload) => {
  return {
    type: SET_SHOWROMS_PRODUCT,
    payload,
  };
};

export const deleteProductInCartAC = (productId) => {
  return {
    type: DEL_CART_PRODUCT,
    payload: productId,
  };
};

export const deleteProductFromShowroomAC = (productId) => {
  return {
    type: DEL_SHOWROOMS_PRODUCT,
    payload: productId,
  };
};

export const resetCartAC = (backetId) => {
  return {
    type: DEL_CART,
    payload: backetId,
  };
};

export const setCurrentUserAC = (data) => {
  return {
    type: SET_CURRENT_USER,
    payload: data,
  };
};

export const setCurrentUserAvatarAC = (file) => {
  return {
    type: SET_USER_AVATAR,
    payload: file,
  };
};

export const setStripePublicTokenAC = (data) => {
  return {
    type: SET_PUBLIC_STRIPE_TOKEN,
    payload: data,
  };
};

export const setProductInvoiceAC = (data) => {
  return {
    type: SET_PRODUCT_INVOICE,
    payload: data,
  };
};

export const setUserPreloaderAC = (data) => {
  return {
    type: SET_USER_LOADING,
    payload: data,
  };
};

export const setCartLimitpopupAC = (data) => {
  return {
    type: SET_CART_LIMIT_POPUP,
    payload: data,
  };
};

export const setCartLimitAC = (data) => {
  return {
    type: SET_CART_LIMIT,
    payload: data,
  };
};

export const setLocaleAC = (data) => {
  return {
    type: SET_LOCALE,
    payload: data,
  };
};

export const setPaymentsStatusAC = (data) => {
  return {
    type: SET_PAYMENT_STATUS,
    payload: data,
  };
};

export const setActivatedEmailShowAC = (data) => {
  return {
    type: SET_ACTIVATED_EMAIL_MODAL,
    payload: data,
  };
};

export const setPurchasesLimitAC = (data) => {
  return {
    type: SET_PURCHASES_LIMIT,
    payload: data,
  };
};

export const setModalLoginShowAC = (data) => {
  return {
    type: SET_LOGIN_MODAL_SHOW,
    payload: data,
  };
};

export const setModalSignupShowAC = (data) => {
  return {
    type: SET_SIGNUP_MODAL_SHOW,
    payload: data,
  };
};

export const setModalRecoveryShowAC = (data) => {
  return {
    type: SET_RECOVERY_MODAL_SHOW,
    payload: data,
  };
};

export const setPaymentDataAC = (data) => {
  return {
    type: SET_PAYMENT_DATA,
    payload: data,
  };
};

export const setUserBillsAC = (data) => {
  return {
    type: SET_USER_BILLS_DATA,
    payload: data,
  };
};

export const setBillsPaginationAmountAC = (data) => {
  return {
    type: SET_BILLS_AMOUNT,
    payload: data,
  };
};

export const setBillsLoadingAC = (data) => {
  return {
    type: SET_BILLS_LOADING,
    payload: data,
  };
};

export const setPaidProjectsAC = (projects) => {
  return {
    type: SET_PAID_PROJECTS,
    payload: projects,
  };
};

export const setPaidProductsAC = (products) => {
  return {
    type: SET_PAID_PRODUCTS,
    payload: products,
  };
};

export const setPurchasesLoadingAC = (data) => {
  return {
    type: SET_PURCHASES_LOADING,
    payload: data,
  };
};

export const setCartLoadingAC = (data) => {
  return {
    type: SET_CART_LOADING,
    payload: data,
  };
};

export const setSearchPageAC = (payload) => {
  return {
    type: SET_SEARCH_PAGE,
    payload,
  };
};

export const setDeviceTypeAC = (data) => {
  return {
    type: SET_DEVICE_TYPE,
    payload: data,
  };
};

export const setIsMobileScreenViewAC = (data) => {
  return {
    type: SET_MOBILE_SCREEN,
    payload: data,
  };
};

export const setVisibleSubFooterAC = (data) => {
  return {
    type: SET_VISIBLE_SUBFOOTER,
    payload: data,
  }
}

export const setModalGetFreeProductAC = (data) => {
  return {
    type: SET_GET_FREE_MODAL_SHOW,
    payload: data,
  }
}
