export const dateLib = {
  getMonth() {
    return [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
  },

  getCurrentMonth() {
    const date = new Date();
    return this.getMonth()[date.getMonth()];
  },

  fromDateToPMAM(createdAt) {
    const date = new Date(createdAt);
    let hours = date.getHours();
    let minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'pm' : 'am';

    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    const strTime = `${hours}:${minutes}${ampm}`;

    return strTime;
  },

  fromDateToDatemonth(dateString) {

    const date = new Date(dateString);

    const month = date.toLocaleString('en-US', { month: 'long' });

    return `${date.getDate()} ${month}`;
  },

  fromDateToDatemonthPMAM(dateString) {

    return `${dateLib.fromDateToDatemonth(dateString)}, ${dateLib.fromDateToPMAM(dateString)}`

  },

  getDateQueryString(date) {

    return [
      date.getFullYear(),
      date.getMonth() + 1,
      date.getDate(),
      'T',
      date.getHours(),
      date.getMinutes(),
      date.getSeconds()
    ]
    .map(part => Number.isInteger(part) ? `0000${part}`.slice(-1 * Math.max(`${part}`.length, 2)) : part)
    .join('');

  }
};
